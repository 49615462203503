import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  "data-kt-swapper": "true",
  "data-kt-swapper-mode": "prepend",
  "data-kt-swapper-parent": "{default: '#kt_content_container', 'lg': '#kt_header_container'}",
  class: "page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0"
}
const _hoisted_2 = { class: "d-flex flex-column text-dark fw-bolder my-0 fs-1" }
const _hoisted_3 = {
  key: 0,
  class: "breadcrumb breadcrumb-dot fw-bold fs-base my-1"
}
const _hoisted_4 = { class: "breadcrumb-item text-muted" }
const _hoisted_5 = { class: "breadcrumb-item pe-3 text-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_ctx.breadcrumbs)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          _createElementVNode("li", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: "/console",
              class: "breadcrumb-item text-muted"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Home ")
              ]),
              _: 1
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "breadcrumb-item text-muted"
            }, _toDisplayString(item), 1))
          }), 128)),
          _createElementVNode("li", _hoisted_5, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}