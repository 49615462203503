const DocMenuConfig = [
  {
    heading: 'Advisor',
    route: '/advisor',
    fontIcon: 'las la-arrow-right'
  },
  {
    heading: 'Console',
    route: '/console',
    fontIcon: 'las la-arrow-right'
  },
  {
    heading: 'Learning Paths',
    route: '/learning-paths',
    fontIcon: 'las la-arrow-right'
  },
  {
    heading: 'Sessions',
    route: '/session-manager',
    fontIcon: 'las la-arrow-right'
  },
  {
    heading: 'Library',
    route: '/library-manager',
    fontIcon: 'las la-arrow-right'
  },
  {
    heading: 'Employees',
    route: '/employees',
    fontIcon: 'las la-arrow-right'
  }

  // {
  //   heading: 'Builder',
  //   route: '/builder',
  //   svgIcon: 'media/icons/duotune/arrows/arr001.svg',
  //   fontIcon: 'bi-app-indicator'
  // }
]

export default DocMenuConfig
