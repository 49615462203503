import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "page d-flex flex-row flex-column-fluid"
}
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "wrapper d-flex flex-column flex-row-fluid"
}
const _hoisted_3 = {
  class: "content flex-row-fluid",
  id: "kt_content_container"
}
const _hoisted_4 = { style: {"position":"absolute","left":"55px","bottom":"10px","font-size":"x-small"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTSidebar = _resolveComponent("KTSidebar")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    (_ctx.currentAccount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_KTHeader, {
              breadcrumbs: _ctx.breadcrumbs,
              title: _ctx.pageTitle,
              actionButton: _ctx.actionButton
            }, null, 8, ["breadcrumbs", "title", "actionButton"]),
            _createElementVNode("div", {
              id: "kt_content",
              class: _normalizeClass(["d-flex flex-column-fluid align-items-start", {
          'container-fluid': _ctx.contentWidthFluid,
          'container-xxl': !_ctx.contentWidthFluid,
        }])
            }, [
              (_ctx.asideEnabled)
                ? (_openBlock(), _createBlock(_component_KTAside, {
                    key: 0,
                    lightLogo: _ctx.themeLightLogo,
                    darkLogo: _ctx.themeDarkLogo
                  }, null, 8, ["lightLogo", "darkLogo"]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_router_view)
              ]),
              (_ctx.displaySidebar)
                ? (_openBlock(), _createBlock(_component_KTSidebar, { key: 1 }))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", _hoisted_4, "build " + _toDisplayString(_ctx.buildNo), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_KTUserMenu)
  ], 64))
}