
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n/index'
import KTMenu from '@/layout/aside/Menu.vue'
import UserMenu from '@/layout/header/partials/UserMenu.vue'
import { asideTheme, themeLightLogo } from '@/core/helpers/config'
import { useStore } from 'vuex'
import { Actions } from '@/store/enums/StoreEnums'
import { getMedia } from '@/core/mc-admin-helpers/assets'
// import { Picture as IconPicture } from '@element-plus/icons-vue'
import { buildLocalResourceUrl } from '@/core/mc-admin-helpers/activity-template'
export default defineComponent({
  name: 'KTAside',
  components: {
    KTMenu,
    UserMenu
    // IconPicture

  },
  props: {
    lightLogo: String,
    darkLogo: String
  },
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const avatarPath = ref('') as any

    const currentAccount = () => {
      return store.getters.currentAccount
    }
    const imageUri = computed(() => avatarPath.value)

    watch(currentAccount, (currentValue, oldValue) => {
      if (!currentValue) {
        return
      }
      payloadAvatar.accountUuid = currentValue.accountUuid
      payloadAvatar.avatarPath = currentValue.profileAvatarPath
      store.dispatch(Actions.API_GET_AVATAR_URL, payloadAvatar).then((response) => {
        if (response.payload.image !== null) {
          avatarPath.value = response.payload.image
        } else {
          avatarPath.value = getMedia('/icons/admin-default-icon.png')
        }
      })
    })

    const computedCurrentAccount = computed(() => currentAccount())
    const payloadAvatar = {} as any
    payloadAvatar.accountUuid = currentAccount().accountUuid
    payloadAvatar.avatarPath = currentAccount().profileAvatarPath
    onMounted(() => {
      store.dispatch(Actions.API_GET_AVATAR_URL, payloadAvatar).then((response) => {
        if (response.payload.image !== null) {
          avatarPath.value = response.payload.image
        } else {
          avatarPath.value = getMedia('/icons/admin-default-icon.png')
        }
      })
    })
    return {
      asideTheme,
      themeLightLogo,
      currentAccount,
      buildLocalResourceUrl,
      imageUri,
      computedCurrentAccount,
      t
    }
  }
})
