
import { defineComponent, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n/index'
import { useRoute } from 'vue-router'
import { version } from '@/core/helpers/documentation'
import { asideMenuIcons } from '@/core/helpers/config'
// import MainMenuConfig from '@/core/config/MainMenuConfig'
import CleanMainMenuConfig from '@/core/config/CleanMainMenuConfig'

export default defineComponent({
  name: 'kt-menu',
  components: {},
  setup () {
    const { t, te } = useI18n()
    const route = useRoute()
    const scrollElRef = ref<null | HTMLElement>(null)

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0
      }
    })

    const translate = (text) => {
      if (te(text)) {
        return t(text)
      } else {
        return text
      }
    }

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1
    }

    return {
      hasActiveChildren,
      // MainMenuConfig,
      CleanMainMenuConfig,
      asideMenuIcons,
      version,
      translate
    }
  }
})
